import styled, { css } from 'styled-components'

export const LogoWrap = styled.div`
  position: relative;
  z-index: 1;
  ${props =>
    props.transparentBG &&
    css`
      .dark-text-logo {
        display: none;
      }
      .white-text-logo {
        display: inherit;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
      }
      .dark-logo {
        display: none;
      }
      .white-logo {
        display: inherit;
      }
    `}
  ${props =>
    !props.transparentBG &&
    css`
      .dark-text-logo {
        display: inherit;
        font-weight: 700;
        text-transform: uppercase;
      }
      .white-text-logo {
        display: none;
      }
      .dark-logo {
        display: inherit;
      }
      .white-logo {
        display: none;
      }
    `}
`
